import { Customer } from '../../../data/timetracker-api-client';
import React from 'react';
import { UpdateModal } from '../../common/AddUpdateModal';

interface IUpdateCustomerProps {
    customer: Customer | undefined;
    onAbort: () => void;
    onSave: (customer: Customer) => void;
}

export const UpdateCustomer: React.FC<IUpdateCustomerProps> = ({ customer, onAbort, onSave }) => {
    if (!customer) {
        return null;
    }

    const save = (value: string) => {
        onSave(Object.assign({}, customer, { name: value }));
    };

    const abort = () => onAbort();

    return <UpdateModal valueToUpdate={customer.name} onSave={save} onAbort={abort}></UpdateModal>;
};
