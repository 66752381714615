import './index.scss';

import * as serviceWorker from './serviceWorker';

import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import TimeTracker from './components/TimeTracker';
import { TimeTrackerProvider } from './components/TimeTrackerContext';
import { msalInstance } from './auth/authConfig';
import { MsalProvider } from '@azure/msal-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Router>
        <MsalProvider instance={msalInstance}>
            <TimeTrackerProvider>
                <TimeTracker />
            </TimeTrackerProvider>
        </MsalProvider>
    </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
