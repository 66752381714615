export const makeApiCall = async (dispatch: any, asyncFunc: () => void) => {
    dispatch({ type: 'set-loading', loading: true });
    const result = await asyncFunc();
    dispatch({ type: 'set-loading', loading: false });
    return result;
};

export const toDateString = (date: Date | null | undefined): string => {
    if (date) {
        return date.toLocaleString('sv-SE').substring(0, 10);
    }
    return '';
}

export const adjustForTimezone = (date: Date) => {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000 * -1;
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
