import './AddEditTime.scss';

import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { Customer, TimeEntry } from '../../../data/timetracker-api-client';
import { ITimeFormData, TimeForm } from './TimeForm';
import React, { useState } from 'react';
import { adjustForTimezone, makeApiCall } from '../../../helpers/helpers';

import { CustomerService } from '../../../services/CustomerService';
import { useTimeTrackerContext } from '../../TimeTrackerContext';

interface IProps {
    enabled?: boolean | undefined;
    time?: TimeEntry | undefined;
    onSave: (time: TimeEntry) => void;
    children: any;
}

export const AddEditTime: React.FC<IProps> = ({ enabled = true, time, onSave, ...props }) => {
    const [state, dispatch] = useTimeTrackerContext();
    const { employee } = state;
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<ITimeFormData>({});

    const onFormUpdate = (data: ITimeFormData) => {
        const { date, reportRange } = formData;
        setFormData(data);
        if (date !== data.date || reportRange !== data.reportRange) {
            loadCustomers(data.date, data.reportRange);
        }
    };

    const loadCustomers = (date: Date | undefined, reportRange: boolean | undefined) => {
        if (employee?.id) {
            const getCustomersTask = reportRange
                ? CustomerService.getCustomersByEmployeeAsync(employee.id)
                : CustomerService.getCustomersByEmployeeAndDateAsync(employee.id, date || new Date());
            makeApiCall(dispatch, async () => setCustomers(await getCustomersTask));
        }
    };
    
    const saveTimeEntry = () => {
        const { date, dateRange, description, overtime, quantity, reportRange, staffing } = formData;
        if (staffing && reportRange && dateRange) {
            dateRange.forEach(date => {
                onSave({
                    date,
                    description,
                    isOvertime: overtime,
                    quantity,
                    staffing_Id: staffing?.id || 0,
                });
            });
            setOpen(false);
        } else if (staffing && date) {
            onSave({
                id: time?.id || undefined,
                date: date,
                description,
                isOvertime: overtime,
                quantity,
                staffing_Id: staffing?.id || 0,
            });
            setOpen(false);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleTriggerClick = () => {
        if (time && time.date) {
            setFormData({ 
                date: time.date,
                reportRange: false,
            })
            loadCustomers(adjustForTimezone(new Date(time.date)), false);
        }
        setOpen(true);
    }

    if (!employee) {
        return null;
    }
    
    return (
        <Modal
            className='add-edit-time'
            closeOnDimmerClick={false}
            closeOnEscape={true}
            closeIcon={true}
            trigger={
                <Button primary disabled={!enabled} onClick={handleTriggerClick}>
                    {props.children}
                </Button>
            }
            basic
            size='small'
            open={open}
            onClose={() => setOpen(false)}
        >
            <Header icon='archive' content='Rapportera tid' />
            <Modal.Content>
                <TimeForm customers={customers} employee_Id={employee.id} time={time} onFormUpdate={onFormUpdate} />
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={handleCancel}>
                    <Icon name='remove' /> Avbryt
                </Button>
                <Button color='green' inverted onClick={saveTimeEntry}>
                    <Icon name='checkmark' /> Spara
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
