import './AddEditEmployee.scss';

import { Button, Icon, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';

import { Employee } from '../../../../data/timetracker-api-client';

interface IAddEditProps {
    onSave: (employee: Employee) => void;
    selectedEmployee?: Employee;
    isOpen?: boolean;
}

export const AddEditEmployeeModal: React.FC<IAddEditProps> = ({ onSave, selectedEmployee, isOpen }) => {
    const [open, setOpen] = useState(isOpen || false);
    const [employee, setEmployee] = useState<Employee>(
        selectedEmployee || { email: '', employeeNumber: '', firstName: '', lastName: '' }
    );

    const close = () => setOpen(false);

    const save = () => {
        onSave(employee);
        close();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmployee(Object.assign(employee, { [event.target.name]: event.target.value }));
    };

    return (
        <Modal
            basic
            className='add-edit-employee-modal'
            dimmer='blurring'
            open={open}
            onClose={close}
            trigger={
                (selectedEmployee && <i className='edit outline icon pointer' onClick={(e) => setOpen(true)}></i>) || (
                    <Button onClick={() => setOpen(true)} fluid primary>
                        <i className='plus icon'></i>
                        Lägg till anställd
                    </Button>
                )
            }
        >
            {(selectedEmployee && <Modal.Header>Ändra anställd</Modal.Header>) || (
                <Modal.Header>Lägg till anställd</Modal.Header>
            )}
            <Modal.Content>
                <div className='form-field'>
                    <label>Förnamn</label>
                    <div className='ui labeled left icon input'>
                        <i className='inverted tags icon'></i>
                        <input
                            type='text'
                            name='firstName'
                            placeholder='Förnamn'
                            onBlur={handleChange}
                            defaultValue={employee.firstName}
                        />
                    </div>
                </div>
                <div className='form-field'>
                    <label>Efternamn</label>
                    <div className='ui labeled left icon input'>
                        <i className='inverted tags icon'></i>
                        <input
                            type='text'
                            name='lastName'
                            placeholder='Efternamn'
                            onBlur={handleChange}
                            defaultValue={employee.lastName}
                        />
                    </div>
                </div>
                <div className='form-field'>
                    <label>E-post</label>
                    <div className='ui labeled left icon input'>
                        <i className='inverted at icon'></i>
                        <input 
                            type='text' 
                            name='email' 
                            placeholder='E-post' 
                            onBlur={handleChange} 
                            defaultValue={employee.email} />
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={close}>
                    <Icon name='remove' /> Avbryt
                </Button>
                <Button color='green' inverted onClick={save}>
                    <Icon name='checkmark' /> Spara
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
