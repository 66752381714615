import React, { useState } from 'react';

import { AddModal } from '../../common/AddUpdateModal';
import { Project } from '../../../data/timetracker-api-client';
import { UpdateProject } from './UpdateProject';

interface IProps {
    projects: Project[];
    selectedProjectId: number | undefined;
    onAdd: (name: string) => void;
    onDelete: (id: number) => void;
    onSelect: (project: Project) => void;
    onUpdate: (project: Project) => void;
}

export const ProjectsList: React.FC<IProps> = ({
    projects,
    selectedProjectId,
    onAdd,
    onDelete,
    onSelect,
    onUpdate,
}) => {
    const [projectToUpdate, setProjectToUpdate] = useState<Project | undefined>();
    const handleSave = (name: string) => {
        onAdd(name);
    };

    const handleDelete = (project: Project) => {
        if (project && project.id) {
            onDelete(project.id);
        }
    };

    const handleUpdate = (project: Project) => {
        setProjectToUpdate(project);
    };

    const updateAbort = () => {
        setProjectToUpdate(undefined);
    };

    const renderListItem = (project: Project) => {
        return (
            <div
                className={selectedProjectId === project.id ? 'item selected' : 'item'}
                key={project.id}
                onClick={e => onSelect(project)}
            >
                <i className='inverted large lightbulb outline middle aligned icon'></i>
                <div className='content'>
                    <div className='ui right floated'>
                        <i className='inverted edit outline icon pointer' onClick={e => handleUpdate(project)}></i>
                        <i
                            className='inverted trash alternate outline icon pointer'
                            onClick={e => handleDelete(project)}
                        ></i>
                    </div>
                    <div className='primary'>{project.name}</div>
                    <div className='description'>{project.tasks && project.tasks.length} tasks</div>
                </div>
            </div>
        );
    };

    const renderList = () => {
        if (!projects) {
            return <div className='ui inverted centered grid floating message'>Välj en kund</div>;
        }
        if (projects && projects.length === 0) {
            return (
                <React.Fragment>
                    <AddModal entityName='projekt' onSave={handleSave}></AddModal>
                    <UpdateProject project={projectToUpdate} onAbort={updateAbort} onSave={onUpdate}></UpdateProject>
                    <div className='ui centered grid floating message'>Projekt saknas</div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <AddModal entityName='projekt' onSave={handleSave}></AddModal>
                <UpdateProject project={projectToUpdate} onAbort={updateAbort} onSave={onUpdate}></UpdateProject>
                <div className='ui inverted relaxed divided list'>
                    {projects.map((project: Project) => renderListItem(project))}
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <h2 className='ui center aligned icon header'>
                <i className='circular rocket icon'></i>
                Projekt
            </h2>
            {renderList()}
        </React.Fragment>
    );
};
