import { Checkbox, Form, Input } from 'semantic-ui-react';
import { Customer, Staffing, Task, TimeEntry } from '../../../data/timetracker-api-client';
import React, { SyntheticEvent, useState } from 'react';

import DateInput from '../../common/DateInput';
import StaffingBasedDateRange from '../../common/StaffingBasedDateRange';
import { TaskSelector } from '../../common/TaskSelector';

interface IProps {
    customers: Customer[];
    employee_Id: number | undefined;
    time?: TimeEntry | undefined;
    onFormUpdate: (formData: ITimeFormData) => void;
}

export interface ITimeFormData {
    date?: Date | undefined;
    dateRange?: Date[] | undefined;
    description?: string | undefined;
    overtime?: boolean | undefined;
    quantity?: number | undefined;
    reportRange?: boolean | undefined;
    staffing?: Staffing | undefined;
}

export const TimeForm: React.FC<IProps> = ({ customers, employee_Id, time, onFormUpdate }) => {
    const c = customers.find((c) => c.projects?.some((p) => p.tasks?.some((t) => t.id === time?.staffing?.task_Id)));
    const p = c?.projects?.find((p) => p.tasks?.some((t) => t.id === time?.staffing?.task_Id));
    const task = p?.tasks?.find((t) => t.id === time?.staffing?.task_Id);
    const s = task?.staffings?.find((s) => s.id === time?.staffing?.id);

    const [date, setDate] = useState<Date | undefined>(time?.date);
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [description, setDescription] = useState<string>(time?.description || '');
    const [overtime, setOvertime] = useState<boolean>(time?.isOvertime || false);
    const [quantity, setQuantity] = useState<number>(time?.quantity || 0);
    const [reportRange, setReportRange] = useState<boolean>(false);
    const [staffing, setStaffing] = useState<Staffing | undefined>(s);

    const formData = (data?: object) =>
        Object.assign(
            {
                date,
                dateRange,
                description,
                overtime,
                quantity,
                reportRange,
                staffing,
            },
            data
        );

    const handleDescriptionChange = (event: SyntheticEvent, data: any) => {
        setDescription(data.value);
        onFormUpdate(formData({ description: data.value }));
    };

    const handleDateChange = (date: Date) => {
        setDate(date);
        onFormUpdate(formData({ date }));
    };

    const handleDateRangeChange = (range: Date[]) => {
        setDateRange(range);
        onFormUpdate(formData({ dateRange: range }));
    };

    const handleReportRangeChange = (event: SyntheticEvent, data: any) => {
        setReportRange(data.checked);
        onFormUpdate(formData({ reportRange: data.checked }));
    };

    const handleTaskSelected = (task: Task) => {
        const staffing = task.staffings?.find((s) => s.employee_Id === employee_Id);
        setStaffing(staffing);
        onFormUpdate(formData({ staffing }));
    };

    const handleQuantityChange = (event: SyntheticEvent, data: any) => {
        setQuantity(data.value);
        onFormUpdate(formData({ quantity: data.value }));
    };

    const handleOvertimeChange = (event: SyntheticEvent, data: any) => {
        setOvertime(data.checked);
        onFormUpdate(formData({ overtime: data.checked }));
    };

    return (
        <Form>
            {!time && (
                <Form.Field
                    label='Rapportera period'
                    control={Checkbox}
                    onChange={handleReportRangeChange}
                    checked={reportRange}
                ></Form.Field>
            )}
            {!reportRange && <DateInput label='Datum' selected={date} onDateChange={handleDateChange} />}
            <TaskSelector
                customers={customers}
                preSelectedTaskId={staffing?.task_Id}
                onTaskSelected={(task) => handleTaskSelected(task)}
            />
            {reportRange && staffing && <StaffingBasedDateRange staffing={staffing} onChange={handleDateRangeChange} />}
            <div className='display-inline'>
                <Form.Field
                    label='Timmar'
                    control={Input}
                    placeholder='Timmar'
                    onChange={handleQuantityChange}
                    type='number'
                    value={quantity}
                ></Form.Field>
                <Form.Field
                    label='Övertid'
                    control={Checkbox}
                    onChange={handleOvertimeChange}
                    checked={overtime}
                ></Form.Field>
            </div>
            <Form.Field
                label='Beskrivning'
                control={Input}
                placeholder='Beskrivning'
                onChange={handleDescriptionChange}
                value={description}
            ></Form.Field>
        </Form>
    );
};
