import React from 'react';
import { Task } from '../../../data/timetracker-api-client';
import { UpdateModal } from '../../common/AddUpdateModal';

interface IUpdateTaskProps {
    task: Task | undefined;
    onAbort: () => void;
    onSave: (task: Task) => void;
}

export const UpdateTask: React.FC<IUpdateTaskProps> = ({ task, onAbort, onSave }) => {
    if (!task) {
        return null;
    }

    const save = (value: string) => {
        onSave(Object.assign({}, task, { name: value }));
    };

    const abort = () => onAbort();

    return <UpdateModal valueToUpdate={task.name} onSave={save} onAbort={abort}></UpdateModal>;
};
