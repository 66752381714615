import './ConfirmModal.scss';

import { Button, Icon } from 'semantic-ui-react';

import React from 'react';

interface IConfirmProps {
    open: boolean;
    header?: string;
    content?: string;
    cancelButton?: string;
    confirmButton?: string;
    onCancel: () => void;
    onConfirm: () => void;
}

export const ConfirmModal: React.FC<IConfirmProps> = props => {
    if (!props.open) {
        return null;
    }

    return (
        <div className='confirm-modal'>
            <div>
                <div className='header'>{props.header && props.header}</div>
                <div className='content'>{props.content && props.content}</div>
                <div className='actions'>
                    <Button basic color='red' inverted onClick={props.onCancel}>
                        <Icon name='remove' /> {props.cancelButton || 'Avbryt'}
                    </Button>
                    <Button color='green' inverted onClick={props.onConfirm}>
                        <Icon name='checkmark' /> {props.confirmButton || 'OK'}
                    </Button>
                </div>
            </div>
        </div>
    );
};
