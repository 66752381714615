import * as React from 'react';

import { useTimeTrackerContext } from '../TimeTrackerContext';

const Loader: React.FC = () => {
    const [state] = useTimeTrackerContext();
    if (state.loading) {
        return (
            <div className='ui active dimmer' style={{ zIndex: 9999 }}>
                <div className='ui massive text loader'>Loading</div>
            </div>
        );
    }
    return null;
};
export default Loader;
