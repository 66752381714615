import React from 'react';

import { DashboardService } from '../../services/DashboardService';
import { useTimeTrackerContext } from '../TimeTrackerContext';
import { SalesDiagram } from './SalesDiagram';
import { useMakeApiCall } from '../hooks/MakeApiCall';

export const DashboardPage: React.FC = () => {
    const [state] = useTimeTrackerContext();

    const dashboard = useMakeApiCall(
        async (year: number) => DashboardService.getDashboardAsync(year),
        state.fiscalYear
    );

    const chargeabilityStatus = () => {
        return (dashboard?.chargeability || 0) > 80;
    };

    const formatter = new Intl.NumberFormat('sv-SE');

    return (
        <div>
            <h1>Rapporter</h1>
            <div className='ui statistics' style={{ textAlign: 'center' }}>
                <div style={{ margin: 'auto' }}>
                    <div className='ui inverted statistic'>
                        <div className='value'>
                            <i className='small clock icon'></i>
                            {dashboard?.totalHours || 0}
                            {' h'}
                        </div>
                        <div className='label'>Arbetade timmar</div>
                    </div>
                    <div className='ui inverted statistic'>
                        <div className='value'>
                            <i className='small money bill alternate outline icon'></i>
                            {formatter.format(dashboard?.totalSales || 0).replace(/\D00$/, '')}
                            {' kr'}
                        </div>
                        <div className='label'>Försäljning</div>
                    </div>
                    <div className={'ui inverted statistic' + (chargeabilityStatus() ? ' green' : ' red')}>
                        <div className='value'>
                            <i className='small chart line icon'></i>
                            <span>{dashboard?.chargeability || 0} </span>
                            <i className='small percent icon'></i>
                        </div>
                        <div className='label'>Beläggningsgrad</div>
                    </div>
                </div>
                <SalesDiagram salesData={dashboard?.salesData} />
            </div>
        </div>
    );
};
