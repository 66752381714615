import { Employee } from '../data/timetracker-api-client';
import React from 'react';
import { TimeTrackerReducer } from './TimeTrackerReducer';

export interface ITimeTrackerState {
    employee: Employee | undefined;
    fiscalYear: number;
    loading: boolean;
}

export type TimeTrackerAction =
    | { type: 'set-employee'; employee: Employee }
    | { type: 'set-fiscal-year'; year: number }
    | { type: 'set-loading'; loading: boolean };
type Dispatch = (action: TimeTrackerAction) => void;

const initialState: ITimeTrackerState = {
    employee: undefined,
    fiscalYear: new Date(Date.now()).getFullYear(),
    loading: false,
};

const StateContext = React.createContext<ITimeTrackerState | undefined>(undefined);
const DispatchContext = React.createContext<Dispatch | undefined>(undefined);

interface ITimeTrackerProviderProps {
    children: React.ReactNode;
}

const TimeTrackerProvider = ({ children }: ITimeTrackerProviderProps) => {
    const [state, dispatch] = React.useReducer(TimeTrackerReducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

const useTimeTrackerState = () => {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useState must be used within a TimeTrackerProvider');
    }
    return context;
};

const useTimeTrackerDispatch = () => {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useDispatch must be used within a TimeTrackerProvider');
    }
    return context;
};

const useTimeTrackerContext = (): [ITimeTrackerState, Dispatch] => [useTimeTrackerState(), useTimeTrackerDispatch()];

export { TimeTrackerProvider, useTimeTrackerContext };
