import { Button, Icon, Modal } from 'semantic-ui-react';
import React, { useState } from 'react';

interface IAddProps {
    entityName: string;
    onSave: (value: string) => void;
}

export const AddModal: React.FC<IAddProps> = ({ entityName, onSave }) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');

    const close = () => setOpen(false);

    const save = () => {
        onSave(value);
        close();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <Modal
            basic
            closeOnDimmerClick={false}
            closeOnEscape={true}
            closeIcon={true}
            dimmer='blurring'
            onClose={close}
            open={open}
            trigger={
                <Button onClick={() => setOpen(true)} fluid primary>
                    <i className='plus icon'></i>
                    Lägg till {entityName}
                </Button>
            }
        >
            <Modal.Header>Lägg till {entityName}</Modal.Header>
            <Modal.Content>
                <div className='ui labeled left icon input'>
                    <i className='inverted tags icon'></i>
                    <input type='text' placeholder={`Ange ${entityName}`} onChange={handleChange} />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={close}>
                    <Icon name='remove' /> Avbryt
                </Button>
                <Button color='green' inverted onClick={save}>
                    <Icon name='checkmark' /> {`Skapa ${entityName}`}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

interface IUpdateProps {
    valueToUpdate: string | undefined;
    onAbort: () => void;
    onSave: (value: string) => void;
}

export const UpdateModal: React.FC<IUpdateProps> = ({ valueToUpdate, onAbort, onSave }) => {
    const [value, setValue] = useState(valueToUpdate);

    const abort = () => {
        onAbort();
    };

    const save = () => {
        if (value) {
            onSave(value);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <Modal
            basic
            closeOnDimmerClick={false}
            closeOnEscape={true}
            closeIcon={true}
            dimmer='blurring'
            onClose={onAbort}
            open={true}
        >
            <Modal.Header>Redigera</Modal.Header>
            <Modal.Content>
                <div className='ui labeled left icon input'>
                    <i className='inverted tags icon'></i>
                    <input type='text' value={value} onChange={handleChange} />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={abort}>
                    <Icon name='remove' /> Avbryt
                </Button>
                <Button color='green' inverted onClick={save}>
                    <Icon name='checkmark' /> Spara
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
