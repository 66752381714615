import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { SalesYTD } from '../../data/timetracker-api-client';
import moment from 'moment';
import 'chartjs-adapter-moment';
import { useTimeTrackerContext } from '../TimeTrackerContext';

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Title, Tooltip, Legend);

interface IProps {
    salesData: SalesYTD[] | undefined;
}

const colors = {
    L0: 'rgb(80, 110, 90)', // green
    L1: 'rgb(130, 80, 120)', // pink
    L2: 'rgb(102, 252, 241)', // current year
    grid: 'rgb(50, 50, 50)',
    ticks: 'rgb(102, 252, 241)',
    theme: 'rgb(102, 252, 241)',
};

export const SalesDiagram: React.FC<IProps> = ({ salesData }) => {
    const [state] = useTimeTrackerContext();
    const startDate: moment.Moment = moment().year(state.fiscalYear).month(0).date(0);
    const labels = new Array(366).fill('').map((_, i) => startDate.add(1, 'days').format('yyyy-MM-DD'));
    const data = salesData
        ? {
              labels,
              datasets: salesData
                  .map(({ year, sales }, i) => {
                      // hack to dynamically access object prop in typescript.
                      type ObjectKey = keyof typeof colors;
                      const c = ('L' + i) as ObjectKey;
                      return {
                          label: `${year}`,
                          data: sales || [],
                          borderColor: colors[c],
                          backgroundColor: colors[c],
                      };
                  })
                  .reverse(),
          }
        : {
              labels,
              datasets: [],
          };

    const options: any = {
        responsive: true,
        elements: {
            point: {
                radius: 0,
            },
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Försäljning',
                color: colors['theme'],
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'month',
                    tooltipFormat: 'DD MMM',
                    displayFormats: {
                        month: '  MMM',
                    },
                },
                title: {
                    display: true,
                    text: 'Månad',
                },
                ticks: {
                    align: 'start',
                    color: colors['ticks'],
                },
                grid: {
                    color: colors['grid'],
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'SEK',
                },
                ticks: {
                    color: colors['ticks'],
                },
                grid: {
                    color: colors['grid'],
                },
            },
        },
    };

    if (!salesData) return null;

    return <Line options={options} data={data} />;
};
