import React, { useState } from 'react';

import { AddEditEmployeeModal } from './add-edit-employee/AddEditEmployee';
import { ConfirmModal } from '../../common/ConfirmModal/ConfirmModal';
import { Employee } from '../../../data/timetracker-api-client';

interface IProps {
    employees: Employee[];
    selectedEmployeeId: number | undefined;
    onAdd: (employee: Employee) => void;
    onDelete: (id: number) => void;
    onSelect: (employee: Employee) => void;
    onUpdate: (employee: Employee) => void;
}

export const EmployeesList: React.FC<IProps> = ({
    employees,
    selectedEmployeeId,
    onAdd,
    onDelete,
    onSelect,
    onUpdate,
}) => {
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

    const handleDeleteConfirmed = () => {
        if (selectedEmployee && selectedEmployee.id) {
            onDelete(selectedEmployee.id);
            setSelectedEmployee(undefined);
            setConfirmDeleteOpen(false);
        }
    };

    const confirmDelete = (employee: Employee) => {
        setSelectedEmployee(employee);
        setConfirmDeleteOpen(true);
    };

    const renderListItem = (employee: Employee) => {
        return (
            <div
                className={selectedEmployeeId === employee.id ? 'item selected' : 'item'}
                key={employee.id}
                onClick={e => onSelect(employee)}
            >
                <i className='large user circle middle aligned icon'></i>
                <div className='content'>
                    <div className='ui right floated'>
                        <AddEditEmployeeModal selectedEmployee={employee} onSave={onUpdate}></AddEditEmployeeModal>
                        <i className='trash alternate outline icon pointer' onClick={e => confirmDelete(employee)}></i>
                    </div>
                    <div className='primary'>{employee.fullName}</div>
                    <div className='description'>{employee.employeeNumber} | {employee.email}</div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <h2 className='ui center aligned icon header'>
                <i className='circular users icon'></i>
                Anställda
            </h2>
            <AddEditEmployeeModal onSave={onAdd}></AddEditEmployeeModal>
            <ConfirmModal
                open={confirmDeleteOpen}
                header='Ta bort anställd'
                content='Är du säker?'
                cancelButton='Never mind'
                confirmButton='Do it!'
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={handleDeleteConfirmed}
            />

            <div className='ui inverted relaxed divided list'>
                {employees.map((employee: Employee) => renderListItem(employee))}
            </div>
        </React.Fragment>
    );
};
