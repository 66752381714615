import './FiscalYear.scss';

import { Dropdown, DropdownItemProps, DropdownProps, Icon } from 'semantic-ui-react';
import React, { SyntheticEvent } from 'react';

import { useTimeTrackerContext } from '../TimeTrackerContext';

interface IProps {
    // selectedYear?: number | undefined;
    // onYearSelected: (year: number) => void;
}

// export const FiscalYear: React.FC<IProps> = ({ selectedYear, onYearSelected }) => {
export const FiscalYear: React.FC<IProps> = () => {
    const [state, dispatch] = useTimeTrackerContext();

    const firstYear = 2013;
    const currentYear = new Date(Date.now()).getFullYear();
    const years: DropdownItemProps[] = [{ key: 0, text: `${firstYear}-${currentYear}`, value: 0 }];

    for (let year: number = firstYear; year <= currentYear; year++) {
        years.push({ key: year, text: year.toString(), value: year });
    }

    const onChange = (event: SyntheticEvent, data: DropdownProps) => {
        //onYearSelected(data.value as number);
        dispatch({ type: 'set-fiscal-year', year: data.value as number });
    };

    return (
        <div className='fiscal-year'>
            <Icon name='calendar check outline'></Icon>
            Räkenskapsår
            <Dropdown
                placeholder='Välj år'
                options={years}
                onChange={onChange}
                defaultValue={state.fiscalYear}
            />
        </div>
    );
};
