import './StaffingsList.scss';

import React from 'react';
import { Staffing } from '../../data/timetracker-api-client';
import { toDateString } from '../../helpers/helpers';

const StaffingItem: React.FC<{ item: Staffing; isSelected: boolean; onSelected: () => void }> = ({
    item,
    isSelected,
    onSelected,
}) => {
    return (
        <tr onClick={onSelected} className={isSelected ? 'selected' : ''}>
            <td>{item.employee && item.employee.fullName}</td>
            <td>{toDateString(item.startDate)}</td>
            <td>{toDateString(item.endDate)}</td>
            <td>
                {item.task?.name}
                <small>
                    <i>
                        {item.task?.project?.customer?.name} - {item.task?.project?.name}
                    </i>
                </small>
            </td>
            <td>{item.rate}</td>
        </tr>
    );
};

interface IProps {
    staffings: Staffing[];
    selected: Staffing | undefined;
    onSelected: (staffing: Staffing) => void;
}

export const StaffingsList: React.FC<IProps> = ({ staffings, selected, onSelected }) => {
    return (
        <div className='StaffingsList'>
            <table className='ui inverted fixed single line compact selectable celled table'>
                <thead>
                    <tr>
                        <th className='two wide'>Anställd</th>
                        <th className='two wide'>Startdatum</th>
                        <th className='two wide'>Slutdatum</th>
                        <th className='four wide'>Uppgift</th>
                        <th className='two wide'>Arvode</th>
                    </tr>
                </thead>
                <tbody>
                    {staffings &&
                        staffings.map(s => (
                            <StaffingItem
                                key={s.id}
                                item={s}
                                isSelected={selected?.id === s.id}
                                onSelected={() => onSelected(s)}
                            />
                        ))}
                </tbody>
            </table>
        </div>
    );
};
