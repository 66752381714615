import './LoginPage.scss';

import React, { useEffect } from 'react';

import moment from 'moment';
import { useMsal } from '@azure/msal-react';

export const LoginPage: React.FC = () => {
    const { instance } = useMsal();

    const url = 'palebluedot.jpg';

    const [date] = React.useState(new Date().toLocaleDateString());
    const [time, setTime] = React.useState(new Date().toLocaleTimeString());

    const tick = () => {
        setTime(new Date().toLocaleTimeString());
    };

    useEffect(() => {
        const intervalId = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className='LoginPage'>
            <img alt='login' src={url} />
            <div id='content'>
                <div id='time'>{time}</div>
                <div id='date'>{date}</div>
                <div>week {moment().format('W')}</div>
                <button onClick={() => instance.loginPopup()}>
                    <strong>Sign in</strong>
                </button>
                <div className='pbd'>
                    <h1>Pale Blue Dot</h1>
                    <p>
                        Look again at that dot. That's here. That's home. That's us. On it everyone you love, everyone
                        you know, everyone you ever heard of, every human being who ever was, lived out their lives. The
                        aggregate of our joy and suffering, thousands of confident religions, ideologies, and economic
                        doctrines, every hunter and forager, every hero and coward, every creator and destroyer of
                        civilization, every king and peasant, every young couple in love, every mother and father,
                        hopeful child, inventor and explorer, every teacher of morals, every corrupt politician, every
                        "superstar," every "supreme leader," every saint and sinner in the history of our species lived
                        there - on a mote of dust suspended in a sunbeam.
                    </p>
                    <p>
                        The Earth is a very small stage in a vast cosmic arena. Think of the rivers of blood spilled by
                        all those generals and emperors so that, in glory and triumph, they could become the momentary
                        masters of a fraction of a dot. Think of the endless cruelties visited by the inhabitants of one
                        corner of this pixel on the scarcely distinguishable inhabitants of some other corner, how
                        frequent their misunderstandings, how eager they are to kill one another, how fervent their
                        hatreds.
                    </p>
                    <p>— Carl Sagan</p>
                </div>
            </div>
        </div>
    );
};
