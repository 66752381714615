import * as React from 'react';

import { Dropdown, Grid } from 'semantic-ui-react';

import { CptFilter } from './CptFilter';
import { TimeEntry } from '../../../data/timetracker-api-client';
import { useTimeTrackerContext } from '../../TimeTrackerContext';

interface ITimeFilter {
    AccountingYear?: number;
    CustomerName?: string;
    ProjectName?: string;
    TaskName?: string;
    Month?: Array<string>;
    WeekOfYear?: Array<number>;
}

interface IProps {
    time: Array<TimeEntry>;
    visible: boolean;
    onFilterUpdate: (filter: string) => void;
}

const monthOptions = [
    {
        key: 'Januari',
        text: 'Januari',
        value: '-01',
    },
    {
        key: 'Februari',
        text: 'Februari',
        value: '-02',
    },
    {
        key: 'Mars',
        text: 'Mars',
        value: '-03',
    },
    {
        key: 'April',
        text: 'April',
        value: '-04',
    },
    {
        key: 'Maj',
        text: 'Maj',
        value: '-05',
    },
    {
        key: 'Juni',
        text: 'Juni',
        value: '-06',
    },
    {
        key: 'Juli',
        text: 'Juli',
        value: '-07',
    },
    {
        key: 'Augusti',
        text: 'Augusti',
        value: '-08',
    },
    {
        key: 'September',
        text: 'September',
        value: '-09',
    },
    {
        key: 'Oktober',
        text: 'Oktober',
        value: '-10',
    },
    {
        key: 'November',
        text: 'November',
        value: '-11',
    },
    {
        key: 'December',
        text: 'December',
        value: '-12',
    },
];

const weeksOptions = Array.from({ length: 53 }, (v, k) => k + 1)
    .map(t => ({ key: t, text: t, value: t }))
    .filter((week, pos, arr) => {
        return arr.map(week => week.key).indexOf(week.key) === pos;
    });

export const TimeFilter: React.FC<IProps> = ({ time, visible, onFilterUpdate }) => {
    const [state] = useTimeTrackerContext();
    const [filter, setFilter] = React.useState<ITimeFilter>({ AccountingYear: state.fiscalYear });

    React.useEffect(() => {
        if (state.fiscalYear !== filter.AccountingYear) {
            const f = { ...filter, AccountingYear: state.fiscalYear };
            setFilter(f);
            onFilterUpdate(JSON.stringify(f));
        }
    }, [state.fiscalYear, filter, onFilterUpdate]);

    if (!visible) {
        return null;
    }

    const setFilterAndNotifySubscribers = (f: object) => {
        setFilter(f);
        onFilterUpdate(JSON.stringify(f));
    };

    const handleCptChange = (cptFilter: ITimeFilter) => {
        setFilterAndNotifySubscribers({ ...filter, ...cptFilter });
    };

    const handleMonthChange = (event: React.SyntheticEvent, data: object) => {
        const f = { ...filter, Month: (data as any).value.map((i: string) => `${state.fiscalYear}${i}`) };
        setFilterAndNotifySubscribers(f);
    };

    const handleWeeksChange = (event: React.SyntheticEvent, data: object) => {
        const f = { ...filter, WeekOfYear: (data as any).value };
        setFilterAndNotifySubscribers(f);
    };

    return (
        <div className='ui segment'>
            <Grid columns={3}>
                <Grid.Column>
                    <CptFilter time={time} onFilterUpdate={handleCptChange} />
                </Grid.Column>
                <Grid.Column>
                    <form className='ui form'>
                        {/* <div className='fields'> */}
                        <div className='field'>
                            <label>Månader</label>
                            <Dropdown
                                placeholder='Månader'
                                fluid
                                multiple
                                selection
                                options={monthOptions}
                                onChange={handleMonthChange}
                            />
                        </div>
                        <div className='field'>
                            <label>Veckor</label>
                            <Dropdown
                                placeholder='Veckor'
                                fluid
                                multiple
                                selection
                                options={weeksOptions}
                                onChange={handleWeeksChange}
                            />
                        </div>
                        {/* </div> */}
                    </form>
                </Grid.Column>
            </Grid>
        </div>
    );
};
