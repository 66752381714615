import { Customer, Employee, Staffing } from '../../data/timetracker-api-client';
import React, { useState } from 'react';

import { Button } from 'semantic-ui-react';
import { ConfirmModal } from '../common/ConfirmModal/ConfirmModal';
import { CustomerService } from '../../services/CustomerService';
import { EditStaffing } from './edit-staffing/EditStaffing';
import { EmployeeService } from '../../services/EmployeeService';
import { StaffingService } from '../../services/StaffingService';
import { StaffingsList } from './StaffingsList';
import { makeApiCall } from '../../helpers/helpers';
import { useMakeApiCall } from '../hooks/MakeApiCall';
import { useTimeTrackerContext } from '../TimeTrackerContext';

interface IProps {}

export const StaffingsPage: React.FC<IProps> = () => {
    const [state, dispatch] = useTimeTrackerContext();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [customers, setCustomers] = useState(Array<Customer>());
    const [employees, setEmployees] = useState(Array<Employee>());
    const [staffings, setStaffings] = useState(Array<Staffing>());
    const [selectedStaffing, setSelectedStaffing] = useState<Staffing | undefined>(undefined);

    useMakeApiCall(async (year: number) => {
        await Promise.all([
            StaffingService.getStaffingsByYearAsync(year),
            CustomerService.getCustomersAsync(),
            EmployeeService.getEmployeesAsync(),
        ]).then(([staffings, customers, employees]) => {
            setStaffings(staffings);
            setCustomers(customers);
            setEmployees(employees);
        });
    }, state.fiscalYear);

    const handleStaffingSelected = (staffing: Staffing) => {
        setSelectedStaffing(staffing);
    };

    const handleAddStaffing = async (staffing: Staffing) => {
        makeApiCall(dispatch, async () => {
            await StaffingService.addStaffingAsync(staffing);
            setStaffings(await StaffingService.getStaffingsByYearAsync(state.fiscalYear));
        });
    };

    const handleUpdateStaffing = async (staffing: Staffing) => {
        makeApiCall(dispatch, async () => {
            await StaffingService.updateStaffingAsync(staffing);
            setStaffings(await StaffingService.getStaffingsByYearAsync(state.fiscalYear));
        });
    };

    const handleDeleteConfirmed = () => {
        setConfirmDeleteOpen(false);
        if (selectedStaffing) {
            makeApiCall(dispatch, async () => {
                await StaffingService.deleteStaffingAsync(selectedStaffing);
                setStaffings(await StaffingService.getStaffingsByYearAsync(state.fiscalYear));
            });
        }
    };

    return (
        <div>
            <h1>Bemanning</h1>
            <React.Fragment>
                <Button primary disabled={selectedStaffing === undefined} onClick={() => setConfirmDeleteOpen(true)}>
                    <i className='trash icon'></i>
                    Ta bort bemanning
                </Button>
                <ConfirmModal
                    open={confirmDeleteOpen}
                    header='Ta bort bemanning'
                    content='Är du säker?'
                    cancelButton='Never mind'
                    confirmButton='Do it!'
                    onCancel={() => setConfirmDeleteOpen(false)}
                    onConfirm={handleDeleteConfirmed}
                />
                <EditStaffing
                    header='Ändra Bemanning'
                    customers={customers}
                    employees={employees}
                    staffing={selectedStaffing}
                    onUpdate={handleUpdateStaffing}
                    enabled={!!selectedStaffing}
                >
                    <React.Fragment>
                        <i className='pencil icon'></i>
                        Ändra Bemanning
                    </React.Fragment>
                </EditStaffing>
                <EditStaffing
                    header='Ny Bemanning'
                    customers={customers}
                    employees={employees}
                    onUpdate={handleAddStaffing}
                >
                    <React.Fragment>
                        <i className='plus icon'></i>
                        Ny Bemanning
                    </React.Fragment>
                </EditStaffing>
                <StaffingsList
                    staffings={staffings}
                    selected={selectedStaffing}
                    onSelected={handleStaffingSelected}
                ></StaffingsList>
            </React.Fragment>
        </div>
    );
};
