import { Configuration, PopupRequest, PublicClientApplication } from '@azure/msal-browser';

// Config object to be passed to Msal on creation
const msalConfig: Configuration = {
    auth: {
        clientId: 'edf31a45-d301-4cd1-9f74-85cf18216d1d',
        authority: 'https://login.microsoftonline.com/9acdef40-7bf1-4662-a559-b4f14670485a',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ['openid', 'profile', 'api://edf31a45-d301-4cd1-9f74-85cf18216d1d/user_impersonation'],
};

export const acquireAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        console.log('User is not signed in.');
        return;
    }

    const request = {
        scopes: ['openid', 'profile', 'api://edf31a45-d301-4cd1-9f74-85cf18216d1d/user_impersonation'],
        account: activeAccount || accounts[0],
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};
