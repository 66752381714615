import { ITimeTrackerState, TimeTrackerAction } from './TimeTrackerContext';

export const TimeTrackerReducer = (state: ITimeTrackerState, action: TimeTrackerAction): ITimeTrackerState => {
    switch (action.type) {
        case 'set-employee':
            return { ...state, employee: action.employee };
        case 'set-fiscal-year':
            return { ...state, fiscalYear: action.year };
        case 'set-loading':
            return { ...state, loading: action.loading };
        default:
            throw new Error('Unknown action');
    }
};
