import React, { useState } from 'react';

import DateInput from './DateInput';
import { Staffing } from '../../data/timetracker-api-client';
import moment from 'moment';

interface IProps {
    staffing: Staffing | undefined;
    onChange: (range: Date[]) => void;
}

const getDates = function(startDate: Date, endDate: Date) {
    const dates = [];
    
    const currDate = moment(startDate).startOf('day');
    const lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
        dates.push(currDate.clone().utc(true).toDate());
        currDate.add(1, 'days');
    }

    return dates;
};

export const StaffingBasedDateRange: React.FC<IProps> = ({ staffing, onChange }) => {
    const [dateFrom, setDateFrom] = useState<Date | undefined>();
    const [dateTo, setDateTo] = useState<Date | undefined>();

    const handleDateFromChange = (date: Date) => {
        setDateFrom(date);
        if (dateTo) {
            onChange(getDates(date, dateTo));
        }        
    };
    
    const handleDateToChange = (date: Date) => {
        setDateTo(date);
        if (dateFrom) {
            onChange(getDates(dateFrom, date));
        }        
    };

    return (
        <>
            <DateInput
                minDate={staffing?.startDate && new Date(staffing?.startDate)}
                maxDate={staffing?.endDate && new Date(staffing?.endDate)}
                label='Period från'
                selected={dateFrom}
                onDateChange={handleDateFromChange}
            />
            <DateInput
                minDate={staffing?.startDate && new Date(staffing?.startDate)}
                maxDate={staffing?.endDate && new Date(staffing?.endDate)}
                selected={dateTo}
                label='Period till'
                onDateChange={handleDateToChange}
            />
        </>
    )
};

export default StaffingBasedDateRange;
