import './TimeTracker.scss';
import 'moment/locale/sv';

import { Link, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button } from 'semantic-ui-react';
import { CustomersPage } from './customers/CustomersPage';
import { DashboardPage } from './dashboard/DashboardPage';
import { EmployeeService } from '../services/EmployeeService';
import { EmployeesPage } from './employees/EmployeesPage';
import { FiscalYear } from './common/FiscalYear';
import Footer from './common/Footer/Footer';
import { HomePage } from './home/HomePage';
import Loader from './common/Loader';
import { LoginPage } from './login/LoginPage';
import { StaffingsPage } from './staffings/StaffingsPage';
import { TimePage } from './time/TimePage';
import { makeApiCall } from '../helpers/helpers';
import { useTimeTrackerContext } from './TimeTrackerContext';

const TimeTracker: React.FC = () => {
    const [, dispatch] = useTimeTrackerContext();
    const { accounts, instance } = useMsal();
    const account = useAccount(accounts[0] || {});

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated && account?.username) {
            makeApiCall(dispatch, async () => {
                let employee = await EmployeeService.getEmployeeByEmailAsync(account.username);
                dispatch({ type: 'set-employee', employee });
            });
        }
    }, [isAuthenticated, account, dispatch]);

    let location = useLocation();

    // if (!account && process.env.NODE_ENV !== 'development') {
    //     return <LoginPage onLogin={onSignIn} />;
    // }
    if (!isAuthenticated) {
        return <LoginPage />;
    }

    return (
        <div className='TimeTracker'>
            <header className='TimeTracker-header'>
                <img src={'/voyager-1.jpg'} className='TimeTracker-logo' alt='logo' />
                <h1>uddstrom consulting time mgmt sys</h1>
                <p>{location.pathname}</p>
            </header>
            <section className='TimeTracker-body'>
                <nav>
                    <div className='ui pointing menu'>
                        <Link to='/' className={location.pathname === '/' ? 'item active' : 'item'}>
                            Start
                        </Link>
                        <Link to='/time/' className={location.pathname === '/time/' ? 'item active' : 'item'}>
                            Tid
                        </Link>
                        <Link to='/employees/' className={location.pathname === '/employees/' ? 'item active' : 'item'}>
                            Anställda
                        </Link>
                        <Link to='/customers/' className={location.pathname === '/customers/' ? 'item active' : 'item'}>
                            Kunder
                        </Link>
                        <Link to='/staffings/' className={location.pathname === '/staffings/' ? 'item active' : 'item'}>
                            Bemanning
                        </Link>
                        <Link to='/dashboard/' className={location.pathname === '/dashboard/' ? 'item active' : 'item'}>
                            Dashboard
                        </Link>
                        <Button onClick={() => (account ? instance.logout() : instance.loginPopup())} className='item'>
                            {account ? 'Logout' : 'Login'}
                        </Button>
                        <div className='right menu'>
                            <div className='item'>
                                <FiscalYear />
                            </div>
                        </div>
                    </div>
                </nav>
                <article>
                    <Loader />
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/time/' element={<TimePage></TimePage>} />
                        <Route path='/employees/' element={<EmployeesPage></EmployeesPage>} />
                        <Route path='/customers/' element={<CustomersPage></CustomersPage>} />
                        <Route path='/staffings/' element={<StaffingsPage></StaffingsPage>} />
                        <Route path='/dashboard/' element={<DashboardPage></DashboardPage>} />
                    </Routes>
                </article>
            </section>
            <Footer />
        </div>
    );
};

export default TimeTracker;
