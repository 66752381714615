import './Footer.scss';

import * as React from 'react';

const Footer: React.FC<{}> = () => {
    return (
        <footer>
            <div className=''>&copy;2020 Uddström Consulting AB</div>
            <div>
                <small>
                    Api: <b>{process.env.REACT_APP_API}</b>
                </small>
            </div>
            {/* {process.env.NODE_ENV !== 'production' && (
                <div className=''>
                    <small>
                        Mode: <b>{process.env.NODE_ENV}</b>
                    </small>
                    {' | '}
                    <small>
                        Api: <b>{process.env.REACT_APP_API}</b>
                    </small>
                </div>
            )} */}
        </footer>
    );
};

export default Footer;
