import { Customer, Project, Task } from '../../data/timetracker-api-client';
import { Form, Select } from 'semantic-ui-react';
import React, { SyntheticEvent, useState } from 'react';

interface IProps {
    customers: Customer[];
    preSelectedTaskId?: number;
    onTaskSelected: (task: Task) => void;
}

export const TaskSelector: React.FC<IProps> = ({ customers, preSelectedTaskId, onTaskSelected }) => {
    const c = customers.find((c) => c.projects?.some((p) => p.tasks?.some((t) => t.id === preSelectedTaskId)));
    const p = c?.projects?.find((p) => p.tasks?.some((t) => t.id === preSelectedTaskId));

    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(c);
    const [selectedProject, setSelectedProject] = useState<Project | undefined>(p);
    const selectedTask = p?.tasks?.find((t) => t.id === preSelectedTaskId);

    const customersOptions = customers.map((c) => ({ key: c.id, text: c.name, value: c.id }));
    const projectsOptions =
        (selectedCustomer &&
            selectedCustomer.projects &&
            selectedCustomer.projects.map((p) => ({ key: p.id, text: p.name, value: p.id }))) ||
        [];
    const tasksOptions =
        (selectedProject &&
            selectedProject.tasks &&
            selectedProject.tasks.map((t) => ({ key: t.id, text: t.name, value: t.id }))) ||
        [];

    const handleCustomerSelected = (event: SyntheticEvent, data: any) => {
        const selected = customers.find((c) => c.id === data.value);
        setSelectedCustomer(selected);
    };

    const handleProjectSelected = (event: SyntheticEvent, data: any) => {
        const selected =
            selectedCustomer && selectedCustomer.projects && selectedCustomer.projects.find((p) => p.id === data.value);
        setSelectedProject(selected);
    };

    const handleTaskSelected = (event: SyntheticEvent, data: any) => {
        const selected =
            selectedProject && selectedProject.tasks && selectedProject.tasks.find((t) => t.id === data.value);
        if (selected) {
            onTaskSelected(selected);
        }
    };

    return (
        <React.Fragment>
            <Form.Field
                label='Kund'
                control={Select}
                options={customersOptions}
                placeholder='Välj kund'
                onChange={handleCustomerSelected}
                value={selectedCustomer?.id}
            ></Form.Field>
            <Form.Field
                label='Projekt'
                control={Select}
                options={projectsOptions}
                placeholder='Välj projekt'
                onChange={handleProjectSelected}
                value={selectedProject?.id}
            ></Form.Field>
            <Form.Field
                label='Uppgift'
                control={Select}
                options={tasksOptions}
                placeholder='Välj uppgift'
                onChange={handleTaskSelected}
                value={selectedTask?.id}
            ></Form.Field>
        </React.Fragment>
    );
};
