import './EmployeesPage.scss';

import React, { useState } from 'react';

import { Employee } from '../../data/timetracker-api-client';
import { EmployeeService } from '../../services/EmployeeService';
import { EmployeesList } from './employees-list/EmployeesList';
import { makeApiCall } from '../../helpers/helpers';
import { useMakeApiCall } from '../hooks/MakeApiCall';
import { useTimeTrackerContext } from '../TimeTrackerContext';

export const EmployeesPage: React.FC = () => {
    const [, dispatch] = useTimeTrackerContext();
    const [employees, setEmployees] = useState(Array<Employee>());
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>();

    useMakeApiCall(async () => setEmployees(await EmployeeService.getEmployeesAsync()));

    const handleEmployeeSelected = (employee: Employee) => {
        setSelectedEmployee(employee);
    };

    const handleAddEmployee = async (employee: Employee) => {
        makeApiCall(dispatch, async () => setEmployees(await EmployeeService.addEmployeeAsync(employee)));
    };

    const handleUpdateEmployee = (employee: Employee) => {
        makeApiCall(dispatch, async () => setEmployees(await EmployeeService.updateEmployeeAsync(employee)));
    };

    const handleDeleteEmployee = async (id: number) => {
        makeApiCall(dispatch, async () => {
            await EmployeeService.deleteEmployeeAsync(id);
            setEmployees(await EmployeeService.getEmployeesAsync());
        });
    };

    return (
        <div id='employees-page'>
            <h1>Administration av anställda</h1>
            <div className='ui three column centered stackable grid'>
                <div className='five wide column'>
                    <EmployeesList
                        employees={employees}
                        selectedEmployeeId={selectedEmployee && selectedEmployee.id}
                        onAdd={handleAddEmployee}
                        onDelete={handleDeleteEmployee}
                        onSelect={handleEmployeeSelected}
                        onUpdate={handleUpdateEmployee}
                    ></EmployeesList>
                </div>
            </div>
        </div>
    );
};
