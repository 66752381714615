import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { Customer, Employee, Staffing } from '../../../data/timetracker-api-client';
import { IStaffingFormData, StaffingForm } from '../staffing-form/StaffingForm';
import React, { useState } from 'react';

interface IProps {
    customers: Customer[];
    employees: Employee[];
    staffing?: Staffing | undefined;
    header?: string | undefined;
    enabled?: boolean;
    onUpdate: (staffing: Staffing) => void;
    children: any;
}

export const EditStaffing: React.FC<IProps> = ({ customers, employees, staffing, header, enabled = true, onUpdate, ...props }) => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<IStaffingFormData>({});

    const onFormUpdate = (formData: IStaffingFormData) => {
        setFormData(formData);
    };

    const saveStaffing = () => {
        const { task_Id, employee_Id, startDate, endDate, rate } = formData;
        if (
            task_Id !== undefined &&
            employee_Id !== undefined &&
            startDate !== undefined &&
            endDate !== undefined &&
            rate !== undefined
        ) {
            onUpdate({ task_Id, employee_Id, startDate, endDate, rate });
        }
        setOpen(false);
    };

    return (
        <Modal
            closeOnDimmerClick={false}
            closeOnEscape={true}
            closeIcon={true}
            trigger={
                <Button primary disabled={!enabled} onClick={() => setOpen(enabled)}>
                    {props.children}
                </Button>
            }
            basic
            size='small'
            open={open}
            onClose={() => setOpen(false)}
        >
            <Header icon='archive' content={header || 'Bemanning'} />
            <Modal.Content>
                <StaffingForm
                    customers={customers}
                    employees={employees}
                    staffing={staffing}
                    onFormUpdate={onFormUpdate}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={() => setOpen(false)}>
                    <Icon name='remove' /> Avbryt
                </Button>
                <Button color='green' inverted onClick={saveStaffing}>
                    <Icon name='checkmark' /> Spara
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
