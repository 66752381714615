import { DataService } from './DataService';
import { Employee } from '../data/timetracker-api-client';

export class EmployeeService extends DataService {
    public static async getEmployeesAsync() {
        return await this.client.getEmployees();
    }

    public static async getEmployeeByEmailAsync(email: string) {
        return await this.client.getEmployeeByEmail(email);
    }

    public static async addEmployeeAsync(employee: Employee) {
        await this.client.addEmployee(employee);
        return await this.getEmployeesAsync();
    }

    public static async updateEmployeeAsync(employee: Employee) {
        await this.client.updateEmployee(employee);
        return await this.getEmployeesAsync();
    }

    public static async deleteEmployeeAsync(id: number) {
        await this.client.deleteEmployee(id);
        return await this.getEmployeesAsync();
    }
}
