import { DataService } from './DataService';
import { TimeEntry } from '../data/timetracker-api-client';

export class TimeService extends DataService {
    public static async getTimeAsync() {
        return await this.client.getTime();
    }

    public static async getTimeByYearAsync(year: number | undefined) {
        const filter = (year && `{ 'AccountingYear': '${year}' }`) || '{}';
        return await this.client.getTimeByFilter(filter);
    }

    public static async getTimeByFilterAsync(filter: string | undefined) {
        return await this.client.getTimeByFilter(filter || '{}');
    }

    public static async addTimeAsync(time: TimeEntry) {
        return await this.client.addTimeEntry(time);
    }

    public static async updateTimeAsync(time: TimeEntry) {
        return await this.client.updateTimeEntry(time);
    }

    public static async deleteTimeAsync(time: TimeEntry) {
        if (time.id) {
            await this.client.deleteTimeEntry(time.id);
        }
    }

    public static async getCalendarDataByYearAsync(year: number | undefined) {
        const filter = (year && `{ 'AccountingYear': '${year}' }`) || '{}';
        const time = await this.client.getTimeByFilter(filter);
        const calTime = time.map(t => {
            if (t.quantity && t.quantity > 0 && t.staffing?.rate && t.staffing?.rate > 0) {
                return { date: t.date?.toString().substring(0, 10), val: 'debit' }; // Green
            }
            if (t.quantity && t.quantity > 0) {
                return { date: t.date?.toString().substring(0, 10), val: 'internal' }; // Blue
            }
            return { date: t.date?.toString().substring(0, 10), val: 'public-holiday' }; // Red
        });
        return calTime;
    }
}
