import './CustomersPage.scss';

import { Customer, Project, Task } from '../../data/timetracker-api-client';
import React, { useState } from 'react';

import { CustomerService } from '../../services/CustomerService';
import { CustomersList } from './customers-list/CustomersList';
import { ProjectService } from '../../services/ProjectService';
import { ProjectsList } from './projects-list/ProjectsList';
import { TaskService } from '../../services/TaskService';
import { TasksList } from './tasks-list/TasksList';
import { makeApiCall } from '../../helpers/helpers';
import { useMakeApiCall } from '../hooks/MakeApiCall';
import { useTimeTrackerContext } from '../TimeTrackerContext';

export const CustomersPage: React.FC = () => {
    const [, dispatch] = useTimeTrackerContext();
    const [customers, setCustomers] = useState(Array<Customer>());
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [selectedTask, setSelectedTask] = useState<Task>();

    useMakeApiCall(async () => setCustomers(await CustomerService.getCustomersAsync()));

    const refreshCustomersList = (customers: Customer[]) => {
        setCustomers(customers);

        const newSelectedCustomer = selectedCustomer && customers.find((c) => c.id === selectedCustomer.id);
        const newSelectedProject =
            newSelectedCustomer &&
            newSelectedCustomer.projects &&
            selectedProject &&
            newSelectedCustomer.projects.find((p) => p.id === selectedProject.id);
        const newSelecteTask =
            newSelectedProject &&
            newSelectedProject.tasks &&
            selectedTask &&
            newSelectedProject.tasks.find((t) => t.id === selectedTask.id);

        setSelectedCustomer(newSelectedCustomer);
        setSelectedProject(newSelectedProject);
        setSelectedTask(newSelecteTask);
    };

    const handleAddCustomer = async (name: string) => {
        makeApiCall(dispatch, async () => refreshCustomersList(await CustomerService.addCustomerAsync(name)));
        // Cannot use hook here. Why? See rules of hooks.
        // useMakeApiCall(async () => refreshCustomersList(await CustomerService.addCustomerAsync(name)));
    };

    const handleDeleteCustomer = async (id: number) => {
        makeApiCall(dispatch, async () => refreshCustomersList(await CustomerService.deleteCustomerAsync(id)));
    };

    const handleUpdateCustomer = (customer: Customer) => {
        makeApiCall(dispatch, async () => refreshCustomersList(await CustomerService.updateCustomerAsync(customer)));
    };

    const handleCustomerSelected = (customer: Customer) => {
        setSelectedCustomer(customer);
        // setSelectedProject(null);
    };

    const handleAddProject = async (name: string) => {
        if (selectedCustomer && selectedCustomer.id) {
            const selectedCustomerId = selectedCustomer.id;
            makeApiCall(dispatch, async () => {
                await ProjectService.addProjectAsync(name, selectedCustomerId);
                refreshCustomersList(await CustomerService.getCustomersAsync());
            });
        }
    };

    const handleDeleteProject = async (id: number) => {
        makeApiCall(dispatch, async () => {
            await ProjectService.deleteProjectAsync(id);
            refreshCustomersList(await CustomerService.getCustomersAsync());
        });
    };

    const handleUpdateProject = async (project: Project) => {
        makeApiCall(dispatch, async () => {
            await ProjectService.updateProjectAsync(project);
            refreshCustomersList(await CustomerService.getCustomersAsync());
        });
    };

    const handleProjectSelected = (project: Project) => {
        setSelectedProject(project);
    };

    const handleAddTask = async (name: string) => {
        if (selectedProject && selectedProject.id) {
            const selectedProjectId = selectedProject.id;
            makeApiCall(dispatch, async () => {
                await TaskService.addTaskAsync(name, selectedProjectId);
                refreshCustomersList(await CustomerService.getCustomersAsync());
            });
        }
    };

    const handleDeleteTask = async (id: number) => {
        makeApiCall(dispatch, async () => {
            await TaskService.deleteTaskAsync(id);
            refreshCustomersList(await CustomerService.getCustomersAsync());
        });
    };

    const handleUpdateTask = async (task: Task) => {
        makeApiCall(dispatch, async () => {
            await TaskService.updateTaskAsync(task);
            refreshCustomersList(await CustomerService.getCustomersAsync());
        });
    };

    const handleTaskSelected = (task: Task) => {
        setSelectedTask(task);
    };

    return (
        <div id='customers-page'>
            <h1>Administration av kunder, projekt och uppgifter</h1>
            <div className='ui three column centered stackable grid'>
                <div className='five wide column'>
                    <CustomersList
                        customers={customers}
                        selectedCustomerId={selectedCustomer && selectedCustomer.id}
                        onAdd={handleAddCustomer}
                        onDelete={handleDeleteCustomer}
                        onSelect={handleCustomerSelected}
                        onUpdate={handleUpdateCustomer}
                    ></CustomersList>
                </div>
                <div className='five wide column'>
                    <ProjectsList
                        projects={(selectedCustomer && selectedCustomer.projects) || []}
                        selectedProjectId={selectedProject && selectedProject.id}
                        onAdd={handleAddProject}
                        onDelete={handleDeleteProject}
                        onSelect={handleProjectSelected}
                        onUpdate={handleUpdateProject}
                    ></ProjectsList>
                </div>
                <div className='five wide column'>
                    <TasksList
                        tasks={(selectedProject && selectedProject.tasks) || []}
                        selectedTaskId={selectedTask && selectedTask.id}
                        onAdd={handleAddTask}
                        onDelete={handleDeleteTask}
                        onSelect={handleTaskSelected}
                        onUpdate={handleUpdateTask}
                    ></TasksList>
                </div>
            </div>
        </div>
    );
};
