import Heatmap from './calendar/Heatmap';
import React from 'react';
import { TimeService } from '../../services/TimeService';
import { useMakeApiCall } from '../hooks/MakeApiCall';
import { useTimeTrackerContext } from '../TimeTrackerContext';
import { useAccount, useMsal } from '@azure/msal-react';

export const HomePage: React.FC = () => {
    const [state] = useTimeTrackerContext();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const calendarData = useMakeApiCall(
        async (year: number) => await TimeService.getCalendarDataByYearAsync(year),
        state.fiscalYear
    );

    return (
        <div>
            <h1>Start</h1>
            <p>Välkommen till TimeTracker, ett system i världsklass! :-)</p>
            <br />
            <br />
            {calendarData && calendarData.length > 0 && <Heatmap data={calendarData}></Heatmap>}

            <div className='ui inverted list'>
                {account &&
                    Object.keys(account).map(
                        (key) =>
                            typeof (account as any)[key] === 'string' && (
                                <div className='item' key={key}>
                                    <i className='right triangle icon'></i>
                                    <div className='content'>
                                        <div className='header'>{key}</div>
                                        <div className='description'>{(account as any)[key]}</div>
                                    </div>
                                </div>
                            )
                    )}
            </div>
        </div>
    );
};
