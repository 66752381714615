import './TimeList.scss';

import React from 'react';
import { TimeEntry } from '../../data/timetracker-api-client';
import { toDateString } from '../../helpers/helpers';

const TimeItem: React.FC<{ item: TimeEntry; isSelected: boolean; onSelected: () => void }> = ({
    item,
    isSelected,
    onSelected,
}) => {
    let task, project, customer;
    if (item.staffing && item.staffing.task) {
        task = item.staffing.task.name;
        if (item.staffing.task.project) {
            project = item.staffing.task.project.name;
            if (item.staffing.task.project.customer) {
                customer = item.staffing.task.project.customer.name;
            }
        }
    }
    return (
        <tr onClick={onSelected} className={isSelected ? 'selected' : ''}>
            <td>{item.weekOfYear}</td>
            <td>{toDateString(item.date)}</td>
            <td>{customer}</td>
            <td>{project}</td>
            <td>{task}</td>
            <td>{item.quantity}</td>
            <td>{item.description}</td>
        </tr>
    );
};

interface IProps {
    time: TimeEntry[];
    selected: TimeEntry | undefined;
    onSelected: (time: TimeEntry) => void;
}

export const TimeList: React.FC<IProps> = ({ time, selected, onSelected }) => {
    return (
        <table className='ui small fixed single line compact selectable inverted table'>
            <thead>
                <tr>
                    <th className='one wide'>Vecka</th>
                    <th className='two wide'>Datum</th>
                    <th className='two wide'>Kund</th>
                    <th className='three wide'>Projekt</th>
                    <th className='three wide'>Uppgift</th>
                    <th className='one wide'>Tid</th>
                    <th className='four wide'>Notering</th>
                </tr>
            </thead>
            <tbody>
                {time &&
                    time.map(t => (
                        <TimeItem
                            key={t.id}
                            item={t}
                            isSelected={selected?.id === t.id}
                            onSelected={() => onSelected(t)}
                        />
                    ))}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={5}>
                        <strong>Summa</strong>
                    </th>
                    <th>
                        <strong>{time.reduce((acc, t) => acc + (t.quantity || 0), 0)}</strong>
                    </th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    );
};
