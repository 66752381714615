import React, { useEffect, useState } from 'react';

import { AddEditTime } from './add-edit-time/AddEditTime';
import { Button } from 'semantic-ui-react';
import { ConfirmModal } from '../common/ConfirmModal/ConfirmModal';
import { TimeEntry } from '../../data/timetracker-api-client';
import { TimeFilter } from './filter/TimeFilter';
import { TimeList } from './TimeList';
import { TimeService } from '../../services/TimeService';
import { makeApiCall } from '../../helpers/helpers';
import { useTimeTrackerContext } from '../TimeTrackerContext';

interface IProps {}

export const TimePage: React.FC<IProps> = () => {
    const [state, dispatch] = useTimeTrackerContext();

    const [time, setTime] = useState(Array<TimeEntry>());
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<TimeEntry | undefined>(undefined);

    useEffect(() => {
        if (!filterVisible) {
            makeApiCall(dispatch, async () => setTime(await TimeService.getTimeByYearAsync(state.fiscalYear)));
        }
    }, [state.fiscalYear, filterVisible, dispatch]);

    const handleAddTime = async (time: TimeEntry) => {
        makeApiCall(dispatch, async () => {
            await TimeService.addTimeAsync(time);
            setTime(await TimeService.getTimeByYearAsync(state.fiscalYear));
        });
    };

    const handleEditTime = (time: TimeEntry) => {
        makeApiCall(dispatch, async () => {
            await TimeService.updateTimeAsync(time);
            setTime(await TimeService.getTimeByYearAsync(state.fiscalYear));
        });
    };

    const handleTimeSelected = (time: TimeEntry) => {
        setSelectedTime(time);
    };

    const handleDeleteConfirmed = () => {
        setConfirmDeleteOpen(false);
        if (selectedTime) {
            makeApiCall(dispatch, async () => {
                await TimeService.deleteTimeAsync(selectedTime);
                setTime(await TimeService.getTimeByYearAsync(state.fiscalYear));
            });
        }
    };

    const applyFilter = (filter: string) => {
        makeApiCall(dispatch, async () => setTime(await TimeService.getTimeByFilterAsync(filter)));
    };

    if (state.employee === undefined) {
        return null;
    }

    return (
        <div>
            <h1>Tid</h1>
            <Button primary disabled={selectedTime === undefined} onClick={() => setConfirmDeleteOpen(true)}>
                <i className='trash icon'></i>
                Ta bort tid
            </Button>
            <ConfirmModal
                open={confirmDeleteOpen}
                header='Ta bort tid'
                content='Är du säker?'
                cancelButton='Never mind'
                confirmButton='Do it!'
                onCancel={() => setConfirmDeleteOpen(false)}
                onConfirm={handleDeleteConfirmed}
            />
            <AddEditTime enabled={!!selectedTime} time={selectedTime} onSave={handleEditTime}>
                <React.Fragment>
                    <i className='pencil icon'></i>
                    Ändra tid
                </React.Fragment>
            </AddEditTime>
            <AddEditTime onSave={handleAddTime}>
                <React.Fragment>
                    <i className='plus icon'></i>
                    Rapportera tid
                </React.Fragment>
            </AddEditTime>
            <Button primary onClick={() => setFilterVisible(!filterVisible)}>
                <i className='filter icon'></i>
                {filterVisible ? 'Dölj filter' : 'Visa filter'}
            </Button>

            <TimeFilter time={time} visible={filterVisible} onFilterUpdate={applyFilter} />
            <TimeList time={time} selected={selectedTime} onSelected={handleTimeSelected}></TimeList>
        </div>
    );
};
