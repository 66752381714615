import './StaffingForm.scss';

import { Customer, Employee, Staffing, Task } from '../../../data/timetracker-api-client';
import { Form, Input, Select } from 'semantic-ui-react';
import React, { SyntheticEvent, useState } from 'react';

import DateInput from '../../common/DateInput';
import { TaskSelector } from '../../common/TaskSelector';

interface IProps {
    customers: Customer[];
    employees: Employee[];
    staffing?: Staffing | undefined;
    onFormUpdate: (formData: IStaffingFormData) => void;
}

export interface IStaffingFormData {
    task_Id?: number | undefined;
    employee_Id?: number | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    rate?: number | undefined;
}

export const StaffingForm: React.FC<IProps> = ({ customers, employees, staffing, onFormUpdate }) => {
    const c = customers.find((c) => c.projects?.some((p) => p.tasks?.some((t) => t.id === staffing?.task_Id)));
    const p = c?.projects?.find((p) => p.tasks?.some((t) => t.id === staffing?.task_Id));
    const task = p?.tasks?.find((t) => t.id === staffing?.task_Id);

    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(
        employees?.find((e) => e.id === staffing?.employee_Id)
    );
    const [selectedTask, setSelectedTask] = useState<Task | undefined>(task);
    const [startDate, setStartDate] = useState<Date | undefined>(staffing?.startDate);
    const [endDate, setEndDate] = useState<Date | undefined>(staffing?.endDate);
    const [rate, setRate] = useState<number>(staffing?.rate || 0);

    const employeesOptions = employees.map((e) => ({ key: e.id, text: e.fullName, value: e.id }));

    const formData = (data?: object) =>
        Object.assign(
            {
                task_Id: selectedTask?.id,
                employee_Id: selectedEmployee?.id,
                startDate,
                endDate,
                rate,
            },
            data
        );

    const onTaskSelected = (task: Task) => {
        setSelectedTask(task);
        onFormUpdate(formData({ task_Id: task?.id }));
    };

    const onEmployeeSelected = (_event: SyntheticEvent, data: any) => {
        const empl = employees.find((e) => e.id === data.value);
        setSelectedEmployee(empl);
        onFormUpdate(formData({ employee_Id: empl?.id }));
    };

    const onStartDateChange = (date: Date) => {
        setStartDate(date);
        onFormUpdate(
            formData({
                startDate: date,
                endDate,
            })
        );
    };

    const onEndDateChange = (date: Date) => {
        setEndDate(date);
        onFormUpdate(
            formData({
                startDate,
                endDate: date,
            })
        );
    };

    const onRateSelected = (_event: SyntheticEvent, data: any) => {
        setRate(data.value);
        onFormUpdate(formData({ rate: data.value }));
    };

    return (
        <Form className='staffing-form'>
            <Form.Field
                label='Anställd'
                control={Select}
                options={employeesOptions}
                placeholder='Välj anställd'
                value={selectedEmployee?.id}
                onChange={onEmployeeSelected}
            ></Form.Field>
            <TaskSelector customers={customers} preSelectedTaskId={staffing?.task_Id} onTaskSelected={onTaskSelected} />
            <DateInput label='Startdatum' selected={startDate} onDateChange={onStartDateChange}></DateInput>
            <DateInput label='Slutdatum' selected={endDate} onDateChange={onEndDateChange}></DateInput>
            <Form.Field
                label='Arvode'
                control={Input}
                placeholder='Arvode'
                value={rate}
                onChange={onRateSelected}
            ></Form.Field>
        </Form>
    );
};
