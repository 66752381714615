import { DataService } from './DataService';
import { Task } from '../data/timetracker-api-client';

export class TaskService extends DataService {
    public static async addTaskAsync(name: string, project_Id: number) {
        const task: Task = { name, project_Id };
        await this.client.addTask(task);
    }

    public static async updateTaskAsync(Task: Task) {
        await this.client.updateTask(Task);
    }

    public static async deleteTaskAsync(id: number) {
        await this.client.deleteTask(id);
    }
}
