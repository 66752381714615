import './Heatmap.scss';

import CalendarHeatmap from './react-calendar-heatmap.esm.js';
import React from 'react';
import moment from 'moment';
import { useTimeTrackerContext } from '../../TimeTrackerContext';

const Heatmap: React.FC<{ data: any }> = ({ data }) => {
    const [state] = useTimeTrackerContext();

    const from = new Date(`${state.fiscalYear - 1}-12-31`);
    const to = new Date(data[0].date);
    
    const width = `${moment(data[0].date, 'YYYYMMDD').dayOfYear() / moment(`${state.fiscalYear}1231`, 'YYYYMMDD').dayOfYear() * 100}%`;

    return (
        <div className='react-calendar-heatmap' style={{ width: width }}>
            <CalendarHeatmap
                firstWeekdayMonday={true}
                startDate={from}
                endDate={to}
                values={data}
                gutterSize={3}
                horizontal={true}
                showWeekdayLabels={true}
                weekdayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lörd']}
                classForValue={(value: any) => {
                    if (!value) {
                        return 'color-empty';
                    }
                    return `color-scale-${value.val}`;
                }}
                titleForValue={(value: any) => {
                    return value ? `${value.date}` : '';
                }}
            />
        </div>
    );
};
export default Heatmap;
