import { Customer } from '../data/timetracker-api-client';
import { DataService } from './DataService';

export class CustomerService extends DataService {
    public static async getCustomersAsync() {
        try {
            return await this.client.getCustomers();
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public static async getCustomersByEmployeeAsync(employeeId: number) {
        try {
            return await this.client.getCustomersByEmployee(employeeId);
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public static async getCustomersByEmployeeAndDateAsync(employeeId: number, date: Date) {
        try {
            return await this.client.getCustomersByEmployeeAndDate(employeeId, date);
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public static async addCustomerAsync(name: string) {
        const customer: Customer = { name };
        await this.client.addCustomer(customer);
        return await this.getCustomersAsync();
    }

    public static async updateCustomerAsync(customer: Customer) {
        await this.client.updateCustomer(customer);
        return await this.getCustomersAsync();
    }

    public static async deleteCustomerAsync(id: number) {
        await this.client.deleteCustomer(id);
        return await this.getCustomersAsync();
    }
}
