import { Project } from '../../../data/timetracker-api-client';
import React from 'react';
import { UpdateModal } from '../../common/AddUpdateModal';

interface IUpdateProjectProps {
    project: Project | undefined;
    onAbort: () => void;
    onSave: (project: Project) => void;
}

export const UpdateProject: React.FC<IUpdateProjectProps> = ({ project, onAbort, onSave }) => {
    if (!project) {
        return null;
    }

    const save = (value: string) => {
        onSave(Object.assign({}, project, { name: value }));
    };

    const abort = () => onAbort();

    return <UpdateModal valueToUpdate={project.name} onSave={save} onAbort={abort}></UpdateModal>;
};
