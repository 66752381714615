import { DataService } from './DataService';
import { Staffing } from '../data/timetracker-api-client';

export class StaffingService extends DataService {
    public static async getStaffingsAsync() {
        return await this.client.getStaffings();
    }

    public static async getStaffingsByYearAsync(year: number | undefined) {
        const filter = (year && `{ 'AccountingYear': '${year}' }`) || '{}';
        return await this.client.getStaffingsByFilter(filter);
    }

    public static async addStaffingAsync(staffing: Staffing) {
        await this.client.addStaffing(staffing);
    }

    public static async updateStaffingAsync(staffing: Staffing) {
        await this.client.updateStaffing(staffing);
    }

    public static async deleteStaffingAsync(staffing: Staffing) {
        if (staffing.id) {
            await this.client.deleteStaffing(staffing.id);
        }
    }
}
