import React, { useState } from 'react';

import { AddModal } from '../../common/AddUpdateModal';
import { Customer } from '../../../data/timetracker-api-client';
import { UpdateCustomer } from './UpdateCustomer';

interface IProps {
    customers: Customer[];
    selectedCustomerId: number | undefined;
    onAdd: (name: string) => void;
    onDelete: (id: number) => void;
    onSelect: (customer: Customer) => void;
    onUpdate: (customer: Customer) => void;
}

export const CustomersList: React.FC<IProps> = ({
    customers,
    selectedCustomerId,
    onAdd,
    onDelete,
    onSelect,
    onUpdate,
}) => {
    const [customerToUpdate, setCustomerToUpdate] = useState<Customer | undefined>();

    const handleSave = (name: string) => {
        onAdd(name);
    };

    const handleDelete = (customer: Customer) => {
        if (customer && customer.id) {
            onDelete(customer.id);
        }
    };

    const handleUpdate = (customer: Customer) => {
        setCustomerToUpdate(undefined);
        onUpdate(customer);
    };

    const updateAbort = () => {
        setCustomerToUpdate(undefined);
    };

    const renderListItem = (customer: Customer) => {
        return (
            <div
                className={selectedCustomerId === customer.id ? 'item selected' : 'item'}
                key={customer.id}
                onClick={e => onSelect(customer)}
            >
                <i className='inverted large industry circle middle aligned icon'></i>
                <div className='content'>
                    <div className='ui right floated'>
                        <i
                            className='inverted edit outline icon pointer'
                            onClick={e => setCustomerToUpdate(customer)}
                        ></i>
                        <i
                            className='inverted trash alternate outline icon pointer'
                            onClick={e => handleDelete(customer)}
                        ></i>
                    </div>
                    <div className='primary'>{customer.name}</div>
                    <div className='description'>{customer.projects && customer.projects.length} projekt</div>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            <h2 className='ui center aligned icon header'>
                <i className='circular handshake outline icon'></i>
                Kunder
            </h2>
            <AddModal entityName='kund' onSave={handleSave}></AddModal>
            <UpdateCustomer customer={customerToUpdate} onAbort={updateAbort} onSave={handleUpdate}></UpdateCustomer>
            <div className='ui inverted relaxed divided list'>
                {customers.map((customer: Customer) => renderListItem(customer))}
            </div>
        </React.Fragment>
    );
};
