import React, { useState } from 'react';

import { AddModal } from '../../common/AddUpdateModal';
import { Task } from '../../../data/timetracker-api-client';
import { UpdateTask } from './UpdateTask';

interface IProps {
    tasks: Task[];
    selectedTaskId: number | undefined;
    onAdd: (name: string) => void;
    onDelete: (id: number) => void;
    onSelect: (task: Task) => void;
    onUpdate: (task: Task) => void;
}

export const TasksList: React.FC<IProps> = ({ tasks, selectedTaskId, onAdd, onDelete, onSelect, onUpdate }) => {
    const [taskToUpdate, setTaskToUpdate] = useState<Task | undefined>();

    const handleSave = (name: string) => {
        onAdd(name);
    };

    const handleDelete = (task: Task) => {
        if (task && task.id) {
            onDelete(task.id);
        }
    };

    const handleUpdate = (task: Task) => {
        setTaskToUpdate(task);
    };

    const updateAbort = () => {
        setTaskToUpdate(undefined);
    };

    const renderListItem = (task: Task) => {
        return (
            <div
                className={selectedTaskId === task.id ? 'item selected' : 'item'}
                key={task.id}
                onClick={e => onSelect(task)}
            >
                <i className='inverted large check circle outline middle aligned icon'></i>
                <div className='content'>
                    <div className='ui right floated'>
                        <i className='inverted edit outline icon pointer' onClick={e => handleUpdate(task)}></i>
                        <i
                            className='inverted trash alternate outline icon pointer'
                            onClick={e => handleDelete(task)}
                        ></i>
                    </div>
                    <div className='primary'>{task.name}</div>
                    <div className='description'>{task.staffings && task.staffings.length} arbetare</div>
                </div>
            </div>
        );
    };

    const renderList = () => {
        if (!tasks) {
            return <div className='ui inverted centered grid floating message'>Välj kund och projekt</div>;
        }
        return (
            <React.Fragment>
                <AddModal entityName='uppgift' onSave={handleSave}></AddModal>
                <UpdateTask task={taskToUpdate} onAbort={updateAbort} onSave={onUpdate}></UpdateTask>
                <div className='ui inverted relaxed divided list'>
                    {tasks.map((task: Task) => renderListItem(task))}
                </div>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <h2 className='ui center aligned icon header'>
                <i className='circular tasks icon'></i>
                Uppgifter
            </h2>
            {renderList()}
        </React.Fragment>
    );
};
