import { useCallback, useEffect, useState } from 'react';

import { useTimeTrackerContext } from '../TimeTrackerContext';

export function useMakeApiCall<T>(asyncFunc: (...args: any[]) => Promise<T>, ...args: any[]): T | undefined {
    const [, dispatch] = useTimeTrackerContext();
    const [result, setResult] = useState<T>();
    const memoizedAsyncFunc = useCallback(asyncFunc, [...args]);
    useEffect(() => {
        dispatch({ type: 'set-loading', loading: true });
        let ignoreResult = false;
        async function getData() {
            const res = await memoizedAsyncFunc(...args);
            if (!ignoreResult) setResult(res);
            dispatch({ type: 'set-loading', loading: false });
        }
        getData();

        return () => {
            ignoreResult = true;
        };
    }, [memoizedAsyncFunc]);
    return result;
}
