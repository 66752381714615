import "react-datepicker/dist/react-datepicker.css";

import React from "react";

import DatePicker from "react-datepicker";
import { adjustForTimezone } from "../../helpers/helpers";

interface IProps {
    label?: string;
    minDate?: Date;
    maxDate?: Date;
    selected?: Date;
    onDateChange: (date: Date) => void;
}

const DateInput: React.FC<IProps> = ({ label, maxDate, minDate, selected, onDateChange }) => {

    const handleChange = (date: Date) => onDateChange(adjustForTimezone(date));

    return (
        <div className='field'>
            {label && <label>{label}</label>}
            <DatePicker 
                calendarStartDay={1}
                dateFormat="yyyy-MM-dd"
                maxDate={maxDate}
                minDate={minDate}
                onChange={handleChange}
                placeholderText="Välj datum"
                selected={(selected && new Date(selected))} 
                showPopperArrow={false}
                showWeekNumbers={true}
            />
        </div>
    );
};

export default DateInput;