import * as React from 'react';

import { Dropdown } from 'semantic-ui-react';
import { TimeEntry } from '../../../data/timetracker-api-client';

interface IProps {
    time: Array<TimeEntry>;
    onFilterUpdate: (filter: object) => void;
}
interface IOption {
    value: string | undefined;
}

export const CptFilter: React.FC<IProps> = ({ time, onFilterUpdate }) => {
    const [filter, setFilter] = React.useState<{}>();

    const customers: Array<IOption> = time
        .map(t => {
            const c = t.staffing?.task?.project?.customer;
            return { key: c?.id, text: c?.name, value: c?.name };
        })
        .filter((c, pos, arr) => {
            return arr.map(c => c.key).indexOf(c.key) === pos;
        });

    const projects = time
        .map(t => {
            const p = t.staffing?.task?.project;
            return { key: p?.id, text: p?.name, value: p?.name };
        })
        .filter((project, pos, arr) => {
            return arr.map(project => project.key).indexOf(project.key) === pos;
        });

    const tasks = time
        .map(t => {
            const task = t.staffing?.task;
            return { key: task?.id, text: task?.name, value: task?.name };
        })
        .filter((task, pos, arr) => {
            return arr.map(task => task.key).indexOf(task.key) === pos;
        });

    const handleCustomerChange = (event: React.SyntheticEvent, data: object) => {
        const f = { ...filter, customerName: (data as IOption).value };
        setFilter(f);
        onFilterUpdate(f);
    };

    const handleProjectChange = (event: React.SyntheticEvent, data: object) => {
        const f = { ...filter, projectName: (data as IOption).value };
        setFilter(f);
        onFilterUpdate(f);
    };

    const handleTaskChange = (event: React.SyntheticEvent, data: object) => {
        const f = { ...filter, taskName: (data as IOption).value };
        setFilter(f);
        onFilterUpdate(f);
    };

    return (
        <form className='ui form'>
            <div className='fields'>
                <div className='field'>
                    <label>Kunder</label>
                    <Dropdown options={customers} clearable search selection onChange={handleCustomerChange} />
                </div>
            </div>
            <div className='fields'>
                <div className='field'>
                    <label>Projekt</label>
                    <Dropdown options={projects} clearable search selection onChange={handleProjectChange} />
                </div>
            </div>
            <div className='fields'>
                <div className='field'>
                    <label>Uppgifter</label>
                    <Dropdown options={tasks} clearable search selection onChange={handleTaskChange} />
                </div>
            </div>
        </form>
    );
};
