import { DataService } from './DataService';
import { Project } from '../data/timetracker-api-client';

export class ProjectService extends DataService {
    // public async getProjectsAsync() {
    //     return await this.client.getProjects();
    // }
    public static async addProjectAsync(name: string, customer_Id: number) {
        const project: Project = { name, customer_Id };
        await this.client.addProject(project);
    }

    public static async updateProjectAsync(Project: Project) {
        await this.client.updateProject(Project);
    }

    public static async deleteProjectAsync(id: number) {
        await this.client.deleteProject(id);
    }
}
